import { Close, Delete, EditOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Input,
  Stack,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@mui/material";
import * as React from "react";
import NoteViewer from "../../components/Editor";
import { LexicalEditor } from "lexical";
import { useParams } from "react-router-dom";
import useEditServices from "./edit-service-hook";
import { FrontDeskIcon } from "../../components/icons/FrontDeskIcon";
import { HousekeepingIcon } from "../../components/icons/HousekeepingIcon";
import { EngineeringIcon } from "../../components/icons/EngineeringIcon";
import { ConciergeIcon } from "../../components/icons/ConciergeIcon";
import useSubProperty from "../Sub-Property/sp-hook";

const EditService = () => {
  const { serviceID } = useParams();
  const {
    propertyId,
    editData,
    setEditData,
    onInitialEditorState,
    onFormattingChange,
    handleImage,
    loadingImage,
    image,
    setImage,
    onSubmit,
    showForm,
    setShowForm,
    handleSSEdit,
    ssEditData,
    setSSEditData,
    handleAddOrUpdate,
    setIndex,
    index,
    handleSSDelete,
    setIsCustomized,
    isCustomized,
    handleIcon,
    uploadIcon,
    handleAddSP,
    deleteAttachedSubPr,
  } = useEditServices(serviceID);

  const { SubProperties, handleAttachedSS, SPLookup } = useSubProperty();

  const serviceIcons = [
    {
      name: "SpaIcon",
      icon: <FrontDeskIcon />,
    },
    {
      name: "ActivitiesIcon",
      icon: <EngineeringIcon />,
    },
    {
      name: "HousekeepingIcon",
      icon: <HousekeepingIcon />,
    },
    {
      name: "SwimmingPoolIcon",
      icon: <ConciergeIcon />,
    },
  ];

  // if (!editData?.id) {
  //     return <Loader />;
  // }
  return (
    <>
      <Grid container direction={"column"} spacing={2} mt={2}>
        <Grid item md={7}>
          <TextField
            fullWidth
            label="Enter Service Name"
            value={editData?.name || ""}
            required
            onChange={(e) =>
              setEditData({
                ...editData,
                name: e.target.value,
                property_id: propertyId,
              })
            }
          />
        </Grid>
        <Grid item md={7}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Icon</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Category Icon"
              value={editData?.icon || ""}
              onChange={(e) => {
                setEditData({
                  ...editData,
                  icon: e.target.value || "",
                  name: editData?.name || "",
                  property_id: propertyId,
                });
                setIsCustomized(e.target.value === "Customized");
              }}
            >
              {serviceIcons?.map((ic, index) => (
                <MenuItem key={index} value={ic.name}>
                  <ListItemIcon>{ic.icon}</ListItemIcon>
                  {ic.name}
                </MenuItem>
              ))}
              <MenuItem value="Customized">Customized</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {isCustomized && (
          <Grid item md={12}>
            <Typography color="text.secondary" gutterBottom>
              Upload Icon
            </Typography>
            {loadingImage ? (
              <Box
                sx={{
                  height: 100,
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "60px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              (editData?.uploaded_icon || image) && (
                <Box>
                  <img
                    alt="Service"
                    width={"100px"}
                    src={image ? image : editData?.uploaded_icon}
                  />

                  {(uploadIcon || image) && (
                    <Box component={IconButton} onClick={() => setImage("")}>
                      <Close />
                    </Box>
                  )}
                </Box>
              )
            )}
            <Button variant="outlined">
              <label>
                {editData ? "Update Icon" : "Add icon"}
                <Input
                  type="file"
                  sx={{ display: "none", cursor: "pointer" }}
                  onChange={handleIcon}
                />
              </label>
            </Button>
          </Grid>
        )}

        <Grid item>
          <Typography color="text.secondary">Attach Sub Properties</Typography>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Attach Sub Properties
            </InputLabel>
            <Select
              // labelId="demo-simple-select-label"
              // id="demo-simple-select"
              label="Sub Properties"
              defaultValue={""}
              onChange={(e) => {
                handleAddSP(e.target.value);
              }}
            >
              {SubProperties?.map((sp, index) => (
                <MenuItem key={index} value={sp?.id}>
                  {sp?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Sub Services</TableCell>
                  {/* <TableCell>Image</TableCell> */}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {serviceID && !editData?.sub_properties && <CircularProgress/>} */}
                {SPLookup &&
                  editData?.sub_properties &&
                  editData?.sub_properties.map((id, index) => {
                    if (SPLookup[id]?.name) {
                      return (
                        <TableRow key={SPLookup[id]?.id}>
                          <TableCell>{SPLookup[id]?.name}</TableCell>
                          <TableCell>
                            <Typography sx={{ color: "text.secondary" }}>
                              {SPLookup[id]?.sub_services
                                ?.filter((ss) =>
                                  editData?.sub_services?.some(
                                    (ess) => ss?.name === ess?.name
                                  )
                                )
                                ?.map((ss) => ss?.name)
                                ?.join(", ")}
                            </Typography>
                          </TableCell>
                          {/* <TableCell>
            <Box>
              <img alt="Service" width={"100px"} src={SPLookup[id]?.img || ""} />
            </Box>
          </TableCell> */}
                          <TableCell width={500}>
                            <FormControl fullWidth>
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  backgroundColor: "white",
                                  padding: "0 5px",
                                }}
                              >
                                Attach Sub Services
                              </InputLabel>
                              <Select
                                multiple
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                onChange={(e) => {
                                  handleAttachedSS(id, e.target.value);
                                }}
                                label="Attach Sub Services"
                                defaultValue={[]}
                                value={SPLookup[id]?.sub_services || []}
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip
                                        key={value?.name}
                                        label={value?.name}
                                      />
                                    ))}
                                  </Box>
                                )}
                              >
                                {editData?.sub_services?.map((ss, index) => (
                                  <MenuItem key={index} value={ss as any}>
                                    <Checkbox
                                      checked={SPLookup[id]?.sub_services?.some(
                                        (obj) => obj?.name === ss?.name
                                      )}
                                    />
                                    {ss?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Delete" placement="top" arrow>
                              <Box
                                component={Button}
                                bgcolor={"#fff"}
                                onClick={() => deleteAttachedSubPr(id)}
                                color={"#E6170A"}
                              >
                                <Delete />
                              </Box>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return null;
                    }
                  })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item>
          <Typography color="text.secondary">Sub Services</Typography>
        </Grid>

        <Grid item>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceID && (!editData?.sub_services || editData?.sub_services?.length === 0) && <TableRow> <TableCell colSpan={5} align="center">There is no any sub services</TableCell></TableRow>}
                {editData?.sub_services && editData?.sub_services?.length > 0 &&
                  editData?.sub_services.map((row, index) => (
                    <TableRow key={row?.name}>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell>
                        {row?.desc?.toString().startsWith("<") ? (
                          <div
                            contentEditable="false"
                            dangerouslySetInnerHTML={{
                              __html: row?.desc?.toString() || "",
                            }}
                          ></div>
                        ) : (
                          <Typography sx={{ color: "text.secondary" }}>
                            {row?.desc}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box>
                          <img
                            alt="Service"
                            width={"100px"}
                            src={row?.img || ""}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          component={Button}
                          bgcolor={"#fff"}
                          onClick={() => handleSSEdit(index)}
                          color={"#000"}
                        >
                          <EditOutlined />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Delete" placement="top" arrow>
                          <Box
                            component={Button}
                            bgcolor={"#fff"}
                            onClick={() => handleSSDelete(index)}
                            color={"#E6170A"}
                          >
                            <Delete />
                          </Box>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Paper>
        </Grid>

        <Grid item>
          <Button
            onClick={() => {
              if (showForm === true) {
                setSSEditData(null);
                setIndex(null);
              }
              setShowForm(!showForm);
            }}
            color={showForm ? "error" : "primary"}
            variant="outlined"
          >
            {showForm ? "Cancel" : "Add"}
          </Button>
        </Grid>
      </Grid>

      {showForm && (
        <Box
          sx={{
            width: "70%",
            border: "1px solid #d6d6d6",
            padding: 2,
            mt: 2,
            borderRadius: 3,
          }}
        >
          <Stack spacing={2} mt={2}>
            <TextField
              fullWidth
              label="Enter Sub Service Name"
              value={ssEditData?.name || ""}
              onChange={(e) =>
                setSSEditData({ ...ssEditData, name: e.target.value })
              }
            />

            <NoteViewer
              onChange={(
                editorState: string,
                editorInstance?: LexicalEditor
              ) => {
                onFormattingChange(editorState, editorInstance);
              }}
              initialEditorState={(editor: LexicalEditor) => {
                onInitialEditorState(editor);
              }}
            />

            <TextField
              label="Enter Service ID"
              type="number"
              value={ssEditData?.service_id}
              onChange={(e) =>
                setSSEditData({
                  ...ssEditData,
                  service_id: parseInt(e.target.value),
                  name: ssEditData?.name || "",
                })
              }
            />

            <TextField
              label="Enter Booking URL"
              value={ssEditData?.booking_url}
              onChange={(e) =>
                setSSEditData({
                  ...ssEditData,
                  booking_url: e.target.value,
                  name: ssEditData?.name || "",
                })
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ssEditData?.booking_form || false}
                  onChange={(e) =>
                    setSSEditData({
                      ...ssEditData,
                      booking_form: e.target.checked,
                      name: ssEditData?.name || "",
                    })
                  }
                  name="booking_form"
                />
              }
              label="Booking Form"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={ssEditData?.spa_integration || false}
                  onChange={(e) =>
                    setSSEditData({
                      ...ssEditData,
                      spa_integration: e.target.checked,
                      name: ssEditData?.name || "",
                    })
                  }
                  name="spa_integration"
                />
              }
              label="Spa Integration"
            />
            <Box>
              {loadingImage ? (
                <Box
                  sx={{
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "60px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                (ssEditData?.img || image) && (
                  <Box>
                    <img
                      alt="Service"
                      width={"250px"}
                      src={image ? image : ssEditData?.img}
                    />

                    {(ssEditData?.img || image) && (
                      <Box
                        component={IconButton}
                        onClick={() => setImage(null)}
                      >
                        <Close />
                      </Box>
                    )}
                  </Box>
                )
              )}
            </Box>
            <Stack direction={"row"} spacing={2}>
              <Button variant="outlined" sx={{ width: "10%" }}>
                <label>
                  {index !== null ? "Update Photo" : "Add Photo"}
                  <Input
                    type="file"
                    sx={{ display: "none", cursor: "pointer" }}
                    onChange={handleImage}
                  />
                </label>
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "15%" }}
                onClick={handleAddOrUpdate}
              >
                {index !== null ? "Update Sub Service" : "Add Sub Service"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}

      <Stack direction="row" spacing={2} mt={2}>
        <LoadingButton
          onClick={onSubmit}
          variant="contained"
          //   disabled={!isDirty && isDisable}
          loading={false}
        >
          {editData ? "Update Data" : "Submit Data"}
        </LoadingButton>
      </Stack>
    </>
  );
};

export default EditService;
