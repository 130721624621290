import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import UiSwitch from "../../components/toggleButton/UiSwitch";
import useHotelInformation from "../Hotel-Information/hotel-info-hook";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Draggable,
  useDraggable,
  useDraggableContext,
  moveItems,
} from "react-tiny-dnd";
import { useAutoScroll } from "react-tiny-autoscroll";
import { LoadingButton } from "@mui/lab";
import { Controller } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DefaultImage from "../../assets/default.jpg";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../components/Loader";

const Item = ({
  id,
  name,
  sequence,
  active,
  listeners,
  isDragging,
  onToggle,
}: {
  id: any;
  name: any;
  sequence: any;
  active: any;
  listeners: any;
  isDragging: any;
  onToggle(status: boolean, cardName: string): void;
}) => {
  const index = Number(id);
  const opacity = isDragging ? 0.5 : 1;

  let height: string | number = "initial";
  if (index % 3 === 0) {
    height = 110;
  } else if (index % 4 === 0) {
    height = 70;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "15px",
        borderBottom: "1px solid #eee",
      }}
      style={{ height, opacity, backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
        }}
      >
        {sequence}
      </div>
      <div
        style={{
          display: "flex",
          flex: 20,
          alignItems: "center",
        }}
      >
        {name}
      </div>
      <UiSwitch
        checked={active != null ? active : true}
        onChange={() => onToggle(active ? true : false, name || "")}
        label=""
      />

      <div className="dnd-icon" {...listeners}>
        <DragIndicatorIcon />
      </div>
    </Box>
  );
};

const DraggableItem = ({
  index,
  context,
  item,
  onToggle,
}: {
  index: any;
  context: any;
  item: any;
  onToggle: any;
}) => {
  const {
    listeners, // Handler listeners can be passed to Draggable component as well
    isDragging,
  } = useDraggable(context, index);

  return (
    <Draggable context={context} key={item.id} index={index}>
      <Item
        id={item.id}
        sequence={index + 1}
        name={item.cardName}
        active={item.active}
        listeners={listeners}
        isDragging={isDragging}
        onToggle={() =>
          onToggle(item?.active ? true : false, item?.cardName || "")
        }
      />
    </Draggable>
  );
};

const Settings = () => {
  const {
    propertyRegister,
    handleSubmitProperty,
    isDirtyProperty,
    onSubmitProperty,
    setPropertyValue,
    propertyWatch,
    propertyReset,
    loading,
    handlePropertyImage,
    isDisableProperty,
    CreateCheckWithComboMeals,
    GetDiscounts,
    GetServiceCharges,
    propertyControl,
    Details,
    onSave,
    onLayoutUpdate,
    onPalmWaveIconUpdate,
    onWhatsappIconUpdate,
    onStartRatingUpdate,
    Loading,
  } = useHotelInformation();
  const [showAPI, setShowAPI] = React.useState(false);
  const Font = [
    { fontName: "Roboto", fontstyle: "Roboto" },
    { fontName: "Open Sans", fontstyle: "Open Sans" },
    { fontName: "Montserrat", fontstyle: "Montserrat" },
    { fontName: "Poppins", fontstyle: "Poppins" },
    { fontName: "Source Sans Pro", fontstyle: "Source Sans Pro" },
    { fontName: "Raleway", fontstyle: "Raleway" },
    { fontName: "Ubuntu", fontstyle: "Ubuntu" },
    { fontName: "Work Sans", fontstyle: "Work Sans" },
    { fontName: "Lora", fontstyle: "Lora" },
    { fontName: "Noto Serif", fontstyle: "Noto Serif" },
    { fontName: "Times New Roman", fontstyle: "Times New Roman" },
    { fontName: "Manrope", fontstyle: "Manrope" },
  ];

  const [items, setItems] = React.useState<any[]>([]);
  React.useEffect(() => {
    if (Details?.layout) {
      setItems(
        Details?.layout?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
      );
    } else {
      const default_layout = [
        {
          cardName: "InfoCard",
          sequence: 0,
          active: true,
        },
        {
          cardName: "ServiceAndOrder",
          sequence: 1,
          active: true,
        },
        {
          cardName: "QuickServices",
          sequence: 2,
          active: true,
        },
        {
          cardName: "InRoomDining",
          sequence: 4,
          active: true,
        },
        {
          cardName: "FeedbackAndPromotions",
          sequence: 6,
          active: true,
        },
        {
          cardName: "ServiceInformation",
          sequence: 3,
          active: true,
        },
        {
          cardName: "Itineraries",
          sequence: 4,
          active: true,
        },
      ];
      setItems(
        default_layout.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
      );
    }
  }, [Details?.layout]);
  const onDrop = async (dragIndex: number, overIndex: number) => {
    const nextItems = moveItems(items, dragIndex, overIndex);
    setItems(nextItems);
    let layout = nextItems.map((item, i) => ({ ...item, sequence: i }));
    onSave(layout);
  };

  const context = useDraggableContext({
    onDrop,
  });
  const containerRef = React.useRef<HTMLDivElement>(null);
  useAutoScroll({ containerRef, skip: !context.isDragging });

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <Box component={"form"} onSubmit={handleSubmitProperty(onSubmitProperty)}>
        <Typography variant="h4" align="center" fontWeight={600}>
          App Setting
        </Typography>
        <Grid container spacing={1} pt={2}>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12}>
                <TextField
                  {...propertyRegister("api_key")}
                  fullWidth
                  disabled={showAPI ? false : true}
                  type={showAPI ? "text" : "password"}
                  InputLabelProps={{ shrink: true }}
                  label="API Key"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowAPI((show) => !show)}
                          edge="end"
                        >
                          {<EditIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Domain"
                  {...propertyRegister("domain", {
                    maxLength: 30,
                    required: "Domain is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="API URL"
                  {...propertyRegister("api_url", {
                    required: "Api url is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Chat API URL"
                  {...propertyRegister("chat_url", {
                    required: "chat api url is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Name"
                  {...propertyRegister("name", {
                    maxLength: 30,
                    required: "Hotel name is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Currency"
                  {...propertyRegister("currency", {
                    required: "Currency is required!",
                  })}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Color"
                  {...propertyRegister("primary_color", {
                    maxLength: 30,
                    required: "primary color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Light"
                  {...propertyRegister("primary_light", {
                    maxLength: 30,
                    required: "primary light is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_light")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Background Color"
                  {...propertyRegister("background_color", {
                    maxLength: 30,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("background_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Tile Color"
                  {...propertyRegister("tile_color", {
                    maxLength: 30,
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("tile_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Restaurant Email"
                  {...propertyRegister("restaurant_email", {})}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Restaurant Phone"
                  {...propertyRegister("restaurant_phone", {})}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Palm Text"
                  {...propertyRegister("palm_text", {})}
                />
              </Grid>
              <Grid item md={6}>
                {/* <div> */}
                <FormControl fullWidth>
                  <InputLabel
                    id="font-select-label"
                    style={{
                      backgroundColor: "white",
                      margin: "2px 5px",
                      padding: "0px 5px 0px 5px",
                    }}
                  >
                    Select a Font
                  </InputLabel>
                  <Select
                    // fullWidth
                    labelId="font-select-label"
                    id="font-select"
                    {...propertyRegister("font", {})}
                    value={`${propertyWatch("font")}`}
                  >
                    {Font.map((ic, index) => (
                      <MenuItem
                        style={{ fontFamily: ic.fontstyle }}
                        key={index}
                        value={ic.fontName}
                      >
                        {ic.fontName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel
                    id="dining-select-label"
                    style={{ backgroundColor: "white", padding: "2px 5px" }}
                  >
                    Show Dining By
                  </InputLabel>
                  <Select
                    // fullWidth
                    labelId="dining-select-label"
                    id="dining-select"
                    {...propertyRegister("by_dining", {})}
                    value={`${propertyWatch("by_dining")}`}
                  >
                    <MenuItem key="Category" value="Category">
                      Category
                    </MenuItem>
                    <MenuItem key="Section" value="Section">
                      Section
                    </MenuItem>
                  </Select>
                </FormControl>
                {/* </div> */}
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Country"
                  {...propertyRegister("country", {})}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="SPA Integration ID"
                  {...propertyRegister("spa_id")}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Sender Email ID"
                  {...propertyRegister("sender_email")}
                />
              </Grid>
              <Grid item md={6} sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyWatch("show_talk_to_us") || false}
                    />
                  }
                  {...propertyRegister("show_talk_to_us")}
                  label="Show Talk To Us"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyWatch("show_main_category") || false}
                    />
                  }
                  {...propertyRegister("show_main_category")}
                  label="Allow to Select Main Category"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyWatch("hide_all_menu") || false}
                    />
                  }
                  {...propertyRegister("hide_all_menu")}
                  label="hide_all_menu"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={propertyWatch("send_to_simphony") || false}
                    />
                  }
                  {...propertyRegister("send_to_simphony")}
                  label="send_to_simphony"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={1} m={1}>
              <Grid item md={12} mb={2}>
                <Typography color="text.secondary" gutterBottom>
                  Select Logo
                </Typography>
                <Box
                  component="img"
                  alt="logo"
                  width={"250px"}
                  height={"auto"}
                  src={propertyWatch("img")}
                />
                <Box>
                  <Button variant="outlined">
                    <label>
                      Update Logo
                      <Input
                        type="file"
                        sx={{ display: "none", cursor: "pointer" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePropertyImage(e, setPropertyValue, "img")
                        }
                      />
                    </label>
                  </Button>
                </Box>
              </Grid>
              <Grid item md={5} mb={2}>
                <Typography color="text.secondary" gutterBottom>
                  Select Background image
                </Typography>
                <Box
                  component="img"
                  alt="logo"
                  width={"250px"}
                  height={"auto"}
                  src={propertyWatch("background_image") || DefaultImage}
                />
                <Box>
                  {propertyWatch("background_image") ? (
                    <>
                      {propertyWatch("background_image") ? (
                        <Button variant="outlined">
                          <label>
                            Update Background image
                            <Input
                              type="file"
                              sx={{ display: "none", cursor: "pointer" }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handlePropertyImage(
                                  e,
                                  setPropertyValue,
                                  "background_image"
                                )
                              }
                            />
                          </label>
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            propertyReset((formValues) => ({
                              ...formValues,
                              background_image: Details?.background_image || "",
                            }));
                          }}
                        >
                          Undo
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button variant="outlined">
                      <label>
                        add background image
                        <Input
                          type="file"
                          sx={{ display: "none", cursor: "pointer" }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handlePropertyImage(
                              e,
                              setPropertyValue,
                              "background_image"
                            )
                          }
                        />
                      </label>
                    </Button>
                  )}
                  <IconButton
                    onClick={() =>
                      setPropertyValue("background_image", "", {
                        shouldDirty: true,
                      })
                    }
                    color="error"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography id="slider-label" gutterBottom>
                  Background Image Transperacny:
                </Typography>
                <Controller
                  name="transperancy"
                  control={propertyControl}
                  render={({ field }) => (
                    <>
                      <Slider
                        value={field.value}
                        min={0.1}
                        max={1}
                        step={0.1}
                        onChange={(_, value: any) => {
                          setPropertyValue("transperancy", value, {
                            shouldDirty: true,
                          });
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby="slider-label"
                      />
                      <Typography>{field.value}</Typography>
                    </>
                  )}
                />
              </Grid>
              <Grid item md={5} mb={2}>
                <Typography color="text.secondary" gutterBottom>
                  Select Background logo image
                </Typography>
                <Box
                  component="img"
                  alt="logo"
                  width={"250px"}
                  height={"auto"}
                  src={propertyWatch("logo_tile_bg") || DefaultImage}
                />
                <Box>
                  {propertyWatch("logo_tile_bg") ? (
                    <>
                      {propertyWatch("logo_tile_bg") ? (
                        <Button variant="outlined">
                          <label>
                            Update Background logo image
                            <Input
                              type="file"
                              sx={{ display: "none", cursor: "pointer" }}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handlePropertyImage(
                                  e,
                                  setPropertyValue,
                                  "logo_tile_bg"
                                )
                              }
                            />
                          </label>
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            propertyReset((formValues) => ({
                              ...formValues,
                              background_image: Details?.logo_tile_bg || "",
                            }));
                          }}
                        >
                          Undo
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button variant="outlined">
                      <label>
                        add background logo image
                        <Input
                          type="file"
                          sx={{ display: "none", cursor: "pointer" }}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handlePropertyImage(
                              e,
                              setPropertyValue,
                              "logo_tile_bg"
                            )
                          }
                        />
                      </label>
                    </Button>
                  )}
                  <IconButton
                    onClick={() =>
                      setPropertyValue("logo_tile_bg", "", {
                        shouldDirty: true,
                      })
                    }
                    color="error"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
              <Grid item md={6}>
                <Typography id="slider-label" gutterBottom>
                  Logo Background Image Transperacny:
                </Typography>
                <Controller
                  name="logo_tile_transperancy"
                  control={propertyControl}
                  render={({ field }) => (
                    <>
                      <Slider
                        value={field.value}
                        min={0.1}
                        max={1}
                        step={0.1}
                        onChange={(_, value: any) => {
                          setPropertyValue("logo_tile_transperancy", value, {
                            shouldDirty: true,
                          });
                        }}
                        valueLabelDisplay="auto"
                        aria-labelledby="slider-label"
                      />
                      <Typography>{field.value}</Typography>
                    </>
                  )}
                />
              </Grid>

              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Primary Text Color"
                  {...propertyRegister("primary_text_color", {
                    maxLength: 30,
                    required: "Primary text color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("primary_text_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Secondary Text Color"
                  {...propertyRegister("secondary_text_color", {
                    maxLength: 30,
                    required: "Secondary text color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("secondary_text_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Title Color"
                  {...propertyRegister("title_color", {
                    maxLength: 30,
                    required: "Title color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("title_color")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={6}>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="Button Background Color"
                  {...propertyRegister("button_bg", {
                    maxLength: 30,
                    required: "Button background color is required!",
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <input
                          type="color"
                          value={`${propertyWatch("button_bg")}`}
                          disabled
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/*has_simphony_pos*/}
              {/*simphony_pos_url*/}
              {/*direct_simphony_pos_check*/}
              {/*pos_loc_ref*/}
              {/*pos_rvc_ref*/}
            </Grid>
            <Grid container spacing={1} m={1}>
              <Grid item md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        Boolean(propertyWatch("has_simphony_pos")) || false
                      }
                    />
                  }
                  {...propertyRegister("has_simphony_pos")}
                  label="Has Simphony POS"
                />
              </Grid>
            </Grid>
            {propertyWatch("has_simphony_pos") && (
              <Grid container spacing={1} m={1}>
                <Grid item md={6}>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          propertyWatch("direct_simphony_pos_check") || false
                        }
                      />
                    }
                    {...propertyRegister("direct_simphony_pos_check")}
                    label="Create Check on POS after guest checkout only"
                  /> */}
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="POS API URL"
                    {...propertyRegister("simphony_pos_url")}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Location Code"
                    {...propertyRegister("pos_loc_ref")}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label="Revenue Center Code"
                    {...propertyRegister("pos_rvc_ref")}
                  />
                </Grid>
              </Grid>
            )}
            {
              // Temporary Code to create fake check and get disounts/service charges details
              propertyWatch("has_simphony_pos") &&
                propertyWatch("domain") === "guestdev" && (
                  <Grid container spacing={1} m={1}>
                    <Grid item md={4}>
                      <Button
                        variant="contained"
                        onClick={() => CreateCheckWithComboMeals()}
                      >
                        Create Check
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button
                        variant="contained"
                        onClick={() => GetDiscounts()}
                      >
                        Discounts
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button
                        variant="contained"
                        onClick={() => GetServiceCharges()}
                      >
                        Service Charges
                      </Button>
                    </Grid>
                  </Grid>
                )
            }
          </Grid>
          <Grid container spacing={2} m={2}>
            <Stack direction="row" spacing={2} margin="auto">
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!isDirtyProperty && isDisableProperty}
                loading={false}
              >
                Update Property
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container alignItems={"center"} spacing={2} mb={2}>
          <Grid item>
            {/* <Typography variant="h4" fontWeight={600}>
            Settings
          </Typography> */}
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Card ref={containerRef}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                p={3}
                alignItems={"center"}
              >
                <Typography variant="h5">Layout Setting</Typography>
              </Box>
              {items?.map((item: any, i: any) => (
                <DraggableItem
                  context={context}
                  key={item.cardName}
                  index={i}
                  item={item}
                  onToggle={onLayoutUpdate}
                />
              ))}
              <Divider />
            </Card>
            {Loading ? (
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                position="fixed"
                height={"100%"}
                top={0}
                right={0}
                bottom={0}
                left={0}
                bgcolor="rgba(0, 0, 0, 0.1)"
              >
                <CircularProgress size={25} />
              </Box>
            ) : null}
          </Grid>
          {/* Dashboard Setting */}
          <Grid item xs={12}>
            <Card>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                p={3}
                alignItems={"center"}
              >
                <Typography variant="h5">Guest Dashboard Setting</Typography>
              </Box>
              <Divider />
              <CardContent sx={{ py: "0 !important", px: 3 }}>
                <List>
                  <Fragment>
                    <ListItem>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={8} xl={10}>
                          <UiSwitch
                            checked={Details?.palm_icon ? true : false}
                            label={"Show Palm Wave"}
                            color={(theme) => theme.palette.info.main}
                            onChange={() =>
                              onPalmWaveIconUpdate(
                                Details?.palm_icon ? true : false
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </Fragment>
                  <Divider />
                </List>
                <List>
                  <Fragment>
                    <ListItem>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={8} xl={10}>
                          <UiSwitch
                            checked={Details?.whatsapp_icon ? true : false}
                            label={"Show Whatsapp Icon"}
                            color={(theme) => theme.palette.info.main}
                            onChange={() =>
                              onWhatsappIconUpdate(
                                Details?.whatsapp_icon ? true : false
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </Fragment>
                  <Divider />
                </List>
                <List>
                  <Fragment>
                    <ListItem>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={8} xl={10}>
                          <UiSwitch
                            checked={Details?.star_rating ? true : false}
                            label={"Show Start Rating"}
                            color={(theme) => theme.palette.info.main}
                            onChange={() =>
                              onStartRatingUpdate(
                                Details?.star_rating ? true : false
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    <Divider />
                  </Fragment>
                  <Divider />
                </List>
              </CardContent>
            </Card>
            {Loading ? (
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                position="fixed"
                height={"100%"}
                top={0}
                right={0}
                bottom={0}
                left={0}
                bgcolor="rgba(0, 0, 0, 0.1)"
              >
                <CircularProgress size={25} />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Settings;
